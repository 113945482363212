(function($, window, document) {
  var pluginName = "modal",
  defaults = {
    overlayClass: 'modal-overlay',
    showModalClass: 'is-open'
  },
  location = window.history.location || window.location;

  function Modal(element, options) {
    this.modal = $(element);
    this.settings = $.extend({}, defaults, this.modal.data(), options);
    this.modalClose = '[data-dismiss="modal"]';
    this.modalOpen = `[data-open="#${this.modal.attr('id')}"]`;
    this.modalOverlay = $(`<div class="${this.settings.overlayClass}" />`);
    this._defaults = defaults;
    this._name = pluginName;
    this.init();
  }

  $.extend(Modal.prototype, {
    init: function() {
      $(document).on('click', this.modalClose, this.close.bind(this));
      $(document).on('click', this.modalOpen, this.open.bind(this));

      if (this.modal[0].dataset.isOpen) {
        this.open();
      }

      if (!this.modal[0].dataset.disableOverlay) {
        this.modalOverlay.on('click', this.close.bind(this));
      }
    },

    open: function(e) {
      this.modal.addClass(this.settings.showModalClass);
      this.modal.after(this.modalOverlay);
    },

    close: function() {
      this.modal.removeClass(this.settings.showModalClass);
      this.modalOverlay.detach();
    }
  });

  $.fn[pluginName] = function(option, target) {

    return this.each(function() {
      var $this = $(this);
      var data = $this.data(`plugin_${pluginName}`);

      if (!data) {
        $this.data(`plugin_${pluginName}`, data = new Modal(this, option));
      }

      if (typeof option == 'string') {
        data[option](target);
      }
    });
  };

  $(function() {
    $('.modal').modal();
  });
})(jQuery, window, document);
