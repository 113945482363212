$(document).ready(function() {
  const onClick = function(passwordField, revealIcon) {
    if (passwordField.type === 'password') {
      revealIcon.classList.add('active')
      passwordField.type = 'text'
    } else {
      revealIcon.classList.remove('active')
      passwordField.type = 'password'
    }
  };

  const passwordFieldContainers = document.querySelectorAll('.password-container')

  passwordFieldContainers.forEach((passwordContainer) => {
    let passwordField
    let revealButton

    passwordContainer.childNodes.forEach((child) => {
      if (child.matches('input[type=password')) {
        passwordField = child
      } else if (child.matches('.reveal-password')) {
        revealButton = child
      }
    })
    revealButton.addEventListener('click', () => {
      onClick(passwordField, revealButton);
    });
  });
});
