const resizeText = (el) => {
  const elNewFontSize = parseInt($(el).css('font-size').slice(0, -2)) - 1;

  return $(el).css('font-size', `${elNewFontSize}px`);
};

const textContainerResizer = (textContainer) => {
  const elements = $(textContainer);
  [...elements].forEach((el) => {
    while (el.scrollWidth > el.offsetWidth) {
      resizeText(el);
    }
  });
};

export default textContainerResizer;
