import './polyfills/arrayIncludes';
import './polyfills/stringIncludes';
import './polyfills/closest';
import './polyfills/foreach';
import './polyfills/from';
import './polyfills/matches';

Rails.start()
import './admin/ui_framework/modal'
import Rails from '@rails/ujs';
import './mypage/jquery.formValidate';
import textContainerResizer from './mypage/textContainerResizer/'

import './admin/show_hide_password'

$(document).ready(function () {
  // Responsive Menu
  $(".menu-icon").on('click', function () {
    $(".top-bar").toggleClass("expanded");
  });
  // Close the responsive menu when its clicked outside
  $('body').on('click', function(e) {
    if (!$(e.target).parents().hasClass("top-bar") && !$(e.target).parents().hasClass("menu-icon")) {
      $(".top-bar").removeClass("expanded")
    }
  });

  // Tooltips
  $("[data-tooltip]")
    .on('mouseenter', function () {
      const tooltip = $(`<div class='tooltip'><div class='nub'></div>${$(this).attr("title")}</div>`)
      $(this)
        .data('title', $(this).attr('title'))
        .removeAttr('title')
        .after(tooltip)

      let attr = {
        top: $(this).height() + 10,
        left: $(this).position().left,
        right: "auto",
      }

      if ($(this).position().left + tooltip.outerWidth() > $(window).outerWidth()) {
        attr = {
          top: $(this).height() + 10,
          left: 'auto',
          right: 0,
        }
      }

      tooltip
        .css(attr)
        .fadeIn(200)
    })
    .on('mouseleave', function () {
      $(this)
        .attr('title', $(this).data('title'))
        .removeAttr('data-title')
        .next(".tooltip").fadeOut(200, function () {
          $(this).remove()
        })
    })

  const togglePaymentMethods = function () {
    let paymentMethod = $('input[name="payment_method"]:checked').val();

    if (paymentMethod === "paypal") {
      $("#payment-screen-gmo").hide();
      $("#payment-screen-gmo-creditcard").hide();
      $("#payment-screen-paypal").show();
    } else if (paymentMethod === "credit_card") {
      $("#payment-screen-gmo").hide();
      $("#payment-screen-gmo-creditcard").show();
      $("#payment-screen-paypal").hide();
    } else {
      $("#payment-screen-gmo").show();
      $("#payment-screen-gmo-creditcard").hide();
      $("#payment-screen-paypal").hide();
    }
  }

  $('input[name="payment_method"]').change(togglePaymentMethods);

  togglePaymentMethods();

  $("#new_gmo_payment").formValidate({
    errorClass: "error",
  });

  if ($("#edit_user").length) {
    const highlightError = function () {
      $('[for="user_current_password"]').addClass("error");
      $("#user_current_password").addClass("error");
      $(".annotation").show();
    }

    if ($("#error_explanation").length) {
      highlightError();
    }

    $("#edit_user #user_email, #edit_user #user_password").change(highlightError);
  }
  textContainerResizer('.calendar-desc');

  //Add to home screen

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service worker registered:', registration);
      })
      .catch(registrationError => {
        console.log('Service worker registration failed:', registrationError);
      });
  }

  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;
  });

  $("#add-to-home-screen-button").on("click", (e) => {
    e.preventDefault();
    deferredPrompt.prompt();
  })

  $("#close-add-to-home-screen-button").on("click", (e) => {
    e.preventDefault();
    localStorage.setItem('hideAddToHomeScreen', true)
    $("#add-to-home-screen").hide()
  })

  const isWithinPWA = window.matchMedia('(display-mode: minimal-ui)').matches;
  if (isWithinPWA || localStorage.getItem("hideAddToHomeScreen")) {
    $("#add-to-home-screen").hide()
  }
})
