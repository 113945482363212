if (!Array.prototype.includes) {
  Array.prototype.includes = (function (searchElement) {
    'use strict';
    if (this.indexOf(searchElement) != -1) {
      return true;
    } else {
      return false;
    }
  });
}
