!(function ($, window, document) {

  var pluginName = "formValidate",
    defaults = {
      submitSelector: 'input[type="submit"]',
      errorClass: "is-error",
      focusSelector: 'form[data-target="form-validate"]:first *:input[type!=hidden]:first',
    }

  function FormValidate(element, options) {
    this.form = $(element)
    this.settings = $.extend({}, defaults, this.form.data(), options)
    this.allRequiredInputs = this.form[0].closest(".modal")
      ? this.form.find(":enabled[required]")
      : this.form.find(":enabled[required]:visible")
    this.selectInputs = this.form.find(".select-container")
    this.submit = this.form.find(this.settings.submitSelector)
    this._defaults = defaults
    this._name = pluginName
    this.init()
  }

  $.extend(FormValidate.prototype, {
    init: function () {
      this.getFirstInput().focus()
      this.validate()

      this.allRequiredInputs.each(function () {
        var inputName = $(this).attr("id")
        $('label[for="' + inputName + '"]').addClass("is-required")
      })

      this.allRequiredInputs.on("keyup change", this.validate.bind(this))
      this.allRequiredInputs.on("keyup change", this.validateInput)
    },

    validate: function () {
      if (this.form[0].checkValidity()) {
        this.submit.removeAttr("disabled")
      } else {
        this.submit.attr("disabled", "disabled")
      }
    },

    validateInput: function () {
      if (!this.checkValidity()) {
        $(this).addClass("is-error")
      } else {
        $(this).removeClass("is-error")
      }
    },

    getFirstInput: function () {
      const firstFormSelector = this.form[0].closest(".modal") ? ":input:enabled:first" : ":input:visible:enabled:first"
      const firstFormInput = this.form.find(firstFormSelector)

      if (firstFormInput.prop("tagName").toLowerCase() === "select") {
        return firstFormInput.next(".select-input")
      } else {
        return firstFormInput
      }
    },
  })

  $.fn[pluginName] = function (option, target) {
    return this.each(function () {
      var $this = $(this)
      var data = $this.data("plugin_" + pluginName)

      if (!data) {
        $this.data("plugin_" + pluginName, (data = new FormValidate(this, option)))
      }

      if (typeof option == "string") {
        data[option](target)
      }
    })
  }

  $(document).ready(function () {
    $('form[data-target="form-validate"]').formValidate()
  })

})(window.jQuery, window, document)
